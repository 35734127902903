import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import {
    NyRequestResolver,
    RESPONSE,
    geti18nText,
    NyDataTable,
    getColumnSearch,
    getColumnDateOption,
    NyUtils,
    NySession,
} from '@nybble/nyreact';
import { notification, Button, message } from 'antd';
import { useState } from 'react';
import { CONSTANTS_REQ } from '../../utils/Constants';
import { getTimezone } from '../../utils/Utils';
import AdminFirmwareFileEdit from '../admin/firmware/files/edit';
import TableSettings from '../table_settings';
import HnbReportsEdit from './edit';

const HnbReportsIndex = (props: any) => {
    const [refresh, setRefresh] = useState<any>(null);
    const [loading, setLoading] = useState(false);
    const table = TableSettings();
    function setDefaultFilterValue() {
        return [{ field: 'hnbCashReportTypePeriod', condition: 'equals', value: props.period }];
    }

    const downloadFile = (record: any) => {
        setLoading(true);
        NyRequestResolver.requestGet(CONSTANTS_REQ.HNB_REPORT.DOWNLOAD, { id: record.id }, false, true).then(
            (result: any) => {
                setLoading(false);
                if (result && result.status === RESPONSE.OK) {
                    result.data.filename = record.documentName + '.xml';
                    NyUtils.downloadFile(result);
                    setLoading(false);
                } else {
                    notification['error']({
                        message: geti18nText('app.default.error'),
                        description: message,
                        duration: 0,
                    });
                }
            }
        );
    };

    return (
        <NyDataTable
            url={CONSTANTS_REQ.HNB_REPORT.LIST}
            showRecordModal={true}
            addNewButtonText={geti18nText('hnbReport.table.addButton')}
            hideButtons={!NySession.hasAnyRole(['RULE_HNB_REPORT_CREATE'])}
            modalComponent={HnbReportsEdit}
            setDefaultPageSize={table.setDefaultPageSize(20)}
            onDataLoaded={table.onLoadData}
            readonly={true}
            setDefaultFilterValue={() => table.setDefaultFilterValue(setDefaultFilterValue())}
            setDefaultCurrentPage={table.setDefaultCurrentPage()}
            setDefaultSortOrder={table.setDefaultSortOrder()}
            fetchWhenChange={refresh}
            addedData={props}
            onSaveAndGetID={() => setRefresh((refresh: any) => refresh + 1)}
            columns={[
                {
                    title: geti18nText('hnbReport.table.id'),
                    dataIndex: 'id',
                    width: '10%',
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('number'),
                },
                {
                    title: geti18nText('hnbReport.table.documentName'),
                    dataIndex: 'documentName',
                    sorter: (a: any, b: any) => {},
                    width: '20%',
                    ...getColumnSearch('string'),
                },
                {
                    title: geti18nText('hnbReport.table.reportDate'),
                    dataIndex: 'dateForName',
                    sorter: (a: any, b: any) => {},
                    ...getColumnDateOption(true, undefined, undefined, true),
                    render: (text: string, record: { [index: string]: any }) => {
                        if (record.dateForName) {
                            return (
                                <div>
                                    {new Date(record.dateForName).toLocaleDateString(
                                        NyUtils.getSelectedLocale(),
                                        getTimezone()
                                    )}
                                </div>
                            );
                        }
                    },
                },
                {
                    title: geti18nText('hnbReport.table.created'),
                    dataIndex: 'creation',
                    sorter: (a: any, b: any) => {},
                    ...getColumnDateOption(true, undefined, undefined, true),
                    render: (text: string, record: { [index: string]: any }) => {
                        if (record.creation) {
                            return (
                                <div>
                                    {new Date(record.creation).toLocaleString(
                                        NyUtils.getSelectedLocale(),
                                        getTimezone()
                                    )}
                                </div>
                            );
                        }
                    },
                },

                {
                    title: '',
                    dataIndex: 'action',
                    width: '5%',
                    render: (text: string, record: { [index: string]: any }) => {
                        return (
                            <Button type="primary" onClick={() => downloadFile(record)} loading={loading}>
                                {geti18nText('hnbReport.table.downloadButton')}
                            </Button>
                        );
                    },
                },
            ]}
        />
    );
};
export default HnbReportsIndex;
