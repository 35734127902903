import { geti18nText } from '@nybble/nyreact';
import { Button, Modal } from 'antd';
import { useState } from 'react';
import './patternModal.scss';

const LiveCountingPatternModal = ({
    visiblePatternModal,
    setVisiblePatternModal,
    currencyList,
    liveDataCurrency,
    selectedPatternSettings,
    selectedPattern,
    selectedPatternIndex,
    liveCountingCommandHandler,
}: any) => {
    const maxItemPerPage = 4;
    const itemsPerPage =
        selectedPatternSettings.length > maxItemPerPage ? maxItemPerPage : selectedPatternSettings.length;
    const [currentPage, setCurrentPage] = useState(0);

    const totalPages = Math.ceil(selectedPatternSettings.length / itemsPerPage);

    const getPaginatedData = () => {
        const startIndex = currentPage * itemsPerPage;
        return selectedPatternSettings.slice(startIndex, startIndex + itemsPerPage);
    };

    const handlePageChange = (direction: string) => {
        if (direction === 'next' && currentPage < totalPages - 1) {
            setCurrentPage(currentPage + 1);
        } else if (direction === 'prev' && currentPage > 0) {
            setCurrentPage(currentPage - 1);
        }
    };
    const fieldMappings = [
        { label: 'device.live.counting.view.patern.denomination', key: 'DenomiOrder' },
        { label: 'device.live.counting.view.patern.fitnes', key: 'NoteType' },
        { label: 'device.live.counting.view.patern.direction', key: 'NoteVersion' },
        { label: 'device.live.counting.view.patern.revision', key: 'NoteVersion' },
    ];

    const getFieldDisplay = (st: any, key: any) => {
        if (key != 'DenomiOrder') return st?.[key];

        let denomiOrder = st?.[key];
        if (denomiOrder > 0) return denomiOrder + 'st';
        if (st?.['Denomination'] != undefined) return st?.['Denomination'];
    };

    const patternContent = () => {
        return (
            <table className="live-preview-pattern-table" style={{ width: '100%', marginTop: '10px' }}>
                <thead>
                    <tr>
                        <th style={{ textAlign: 'center', color: '#8181A5', backgroundColor: '#E3E8F4' }}>
                            {geti18nText('device.live.counting.view.patern.name')}
                        </th>
                        {getPaginatedData().map((st: any, index: any) => (
                            <th
                                key={index}
                                style={{ textAlign: 'center', color: '#8181A5', backgroundColor: '#E3E8F4' }}
                            >
                                {`STK${currentPage * itemsPerPage + index + 1}`}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {fieldMappings.map((field, rowIndex) => (
                        <tr key={rowIndex}>
                            <td>{geti18nText(field.label)}</td>
                            {getPaginatedData().map((st: any, colIndex: any) => (
                                <td key={colIndex}>{getFieldDisplay(st, field.key)}</td>
                            ))}
                        </tr>
                    ))}
                </tbody>
                {totalPages > 1 && (
                    <tfoot>
                        <div style={{ marginTop: '5px', textAlign: 'center' }}>
                            <button
                                onClick={() => handlePageChange('prev')}
                                disabled={currentPage === 0}
                                className="pattern-modal-button-table-nav"
                                style={{ marginRight: '10px' }}
                            >
                                {'<'}
                            </button>
                            <span>
                                {currentPage + 1} / {totalPages}
                            </span>
                            <button
                                onClick={() => handlePageChange('next')}
                                disabled={currentPage === totalPages - 1}
                                style={{ marginLeft: '10px' }}
                                className="pattern-modal-button-table-nav"
                            >
                                {'>'}
                            </button>
                        </div>
                    </tfoot>
                )}
            </table>
        );
    };

    return (
        <Modal
            title={<h2>{geti18nText('device.live.counting.view.pattern.change')}</h2>}
            visible={visiblePatternModal}
            width={1000}
            onCancel={() => {
                setVisiblePatternModal(false);
            }}
            footer={[
                <button
                    className="pattern-modal-button"
                    onClick={() => {
                        setVisiblePatternModal(false);
                    }}
                >
                    {geti18nText('app.default.button.cancel')}
                </button>,
                <button
                    className="pattern-modal-button"
                    style={{
                        marginLeft: '20px',
                    }}
                    onClick={() => {
                        setVisiblePatternModal(false);
                        let index = currencyList?.findIndex((x: any) => x.CurrencyName == liveDataCurrency);
                        if (index > -1) {
                            liveCountingCommandHandler(
                                undefined,
                                'SET_PATTERN',
                                currencyList[index]?.CurrencyIndex,
                                selectedPatternIndex
                            );
                        }
                    }}
                >
                    {geti18nText('app.default.button.ok')}
                </button>,
            ]}
        >
            <>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                    <Button
                        style={{
                            fontSize: '26px',
                            color: 'white',
                            backgroundColor: '#4D7CFE',
                            borderColor: '#000',
                            borderRadius: '5px',
                            borderWidth: 'thin',
                            minWidth: '65px',
                            height: '65px',
                            fontStyle: 'italic',
                            boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.5)',
                            cursor: 'pointer',
                            marginRight: '5px',
                        }}
                        onClick={() => {
                            let index = currencyList?.findIndex((x: any) => x.CurrencyName == liveDataCurrency);
                            if (index > -1) {
                                liveCountingCommandHandler(
                                    undefined,
                                    'CHANGE_PATTERN',
                                    currencyList[index]?.CurrencyIndex,
                                    selectedPatternIndex,
                                    -1
                                );
                            }
                        }}
                    >
                        {'<'}
                    </Button>
                    <Button
                        style={{
                            fontSize: '26px',
                            color: 'white',
                            backgroundColor: '#4D7CFE',
                            borderColor: '#000',
                            borderRadius: '5px',
                            borderWidth: 'thin',
                            minWidth: '350px',
                            height: '65px',
                            fontStyle: 'italic',
                            boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.5)',
                            cursor: 'pointer',
                        }}
                        onClick={() => {}}
                    >
                        {selectedPattern}
                    </Button>
                    <Button
                        style={{
                            fontSize: '26px',
                            color: 'white',
                            backgroundColor: '#4D7CFE',
                            borderColor: '#000',
                            borderRadius: '5px',
                            borderWidth: 'thin',
                            minWidth: '65px',
                            height: '65px',
                            fontStyle: 'italic',
                            boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.5)',
                            cursor: 'pointer',
                            marginLeft: '5px',
                        }}
                        onClick={() => {
                            let index = currencyList?.findIndex((x: any) => x.CurrencyName == liveDataCurrency);
                            console.log('currencyList', currencyList);
                            if (index > -1) {
                                liveCountingCommandHandler(
                                    undefined,
                                    'CHANGE_PATTERN',
                                    currencyList[index]?.CurrencyIndex,
                                    selectedPatternIndex,
                                    1
                                );
                            }
                        }}
                    >
                        {'>'}
                    </Button>
                </div>
                {selectedPatternSettings && patternContent()}
            </>
        </Modal>
    );
};

export default LiveCountingPatternModal;
