import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import {
    ENUMS,
    getColumnDateOption,
    getColumnSearch,
    getColumnSearchOption,
    geti18nText,
    NyDataTable,
    NySession,
} from '@nybble/nyreact';
import { Tabs } from 'antd';
import React from 'react';
import { CONSTANTS_REQ } from '../../../utils/Constants';
import { GetEnum, GetEnumNameForValue } from '../../../utils/Enums';
import TableSettings from '../../table_settings';
import DeviceModelEdit from './edit';
const AdminDeviceModelIndex = () => {
    const { TabPane } = Tabs;
    const table = TableSettings();

    function setDefaultFilterValue() {
        return [{ field: 'active', condition: 'equals_bool', value: 1 }];
    }

    const communicationType = () => {
        const aTypes = GetEnum({ enumName: 'COMMUNICATION_TYPE' });
        let types = [];
        for (var key in aTypes) {
            if (aTypes.hasOwnProperty(key)) {
                let ret: any = {};
                ret.id = aTypes[key];
                ret.text = key;
                types.push(ret);
            }
        }
        return types;
    };

    const deviceTypes = () => {
        const aTypes = GetEnum({ enumName: 'DEVICE_TYPE' });
        let types = [];
        for (var key in aTypes) {
            if (aTypes.hasOwnProperty(key)) {
                let ret = { id: undefined, text: '' };
                ret.id = aTypes[key];
                ret.text = key;
                types.push(ret);
            }
        }
        return types;
    };

    const modelPatterns = () => {
        const aTypes = GetEnum({ enumName: 'DEVICE_MODEL_PATTERN_TYPES' });
        let types = [];
        for (var key in aTypes) {
            if (aTypes.hasOwnProperty(key)) {
                let ret = { id: undefined, text: '' };
                ret.id = aTypes[key];
                ret.text = key;
                types.push(ret);
            }
        }
        return types;
    };

    return (
        <NyDataTable
            headerTitle={geti18nText('device.model.table.header')}
            url={CONSTANTS_REQ.DEVICE_MODEL.LIST}
            showRecordModal={true}
            addNewButtonText={geti18nText('device.model.add.new')}
            //hideButtons={!NySession.hasAnyRole(['RULE_ADD_DEVICE_MODEL'])}
            hideButtons={true}
            modalComponent={DeviceModelEdit}
            setDefaultPageSize={table.setDefaultPageSize(20)}
            onDataLoaded={table.onLoadData}
            setDefaultFilterValue={() => table.setDefaultFilterValue(setDefaultFilterValue())}
            setDefaultCurrentPage={table.setDefaultCurrentPage()}
            setDefaultSortOrder={table.setDefaultSortOrder()}
            columns={[
                {
                    title: geti18nText('device.model.table.id'),
                    dataIndex: 'id',
                    width: '10%',
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('number'),
                },
                {
                    title: geti18nText('device.model.table.name'),
                    dataIndex: 'name',
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('string'),
                },
                {
                    title: geti18nText('device.model.table.type'),
                    dataIndex: 'type',
                    width: '20%',
                    render: (text: string, record: { [index: string]: any }) => {
                        return (
                            <div>
                                {geti18nText(
                                    'device.model.type.' +
                                        GetEnumNameForValue({
                                            enumName: 'DEVICE_TYPE',
                                            value: record.type,
                                        })?.toLowerCase()
                                )}
                            </div>
                        );
                    },
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearchOption(deviceTypes()),
                },

                {
                    title: geti18nText('device.table.column.communication.type'),
                    dataIndex: 'communicationType',
                    render: (text: string, record: { [index: string]: any }) => {
                        return (
                            <div>
                                {GetEnumNameForValue({
                                    enumName: 'COMMUNICATION_TYPE',
                                    value: record.communicationType,
                                })}
                            </div>
                        );
                    },
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearchOption(communicationType()),
                },
                {
                    title: geti18nText('device.model.model'),
                    dataIndex: 'model',
                    width: '10%',
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearchOption(modelPatterns()),
                },

                {
                    title: geti18nText('device.model.table.licenseType'),
                    dataIndex: 'licenseType',
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('string'),
                },
                {
                    title: geti18nText('device.model.table.external_id'),
                    dataIndex: 'externalId',
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('string'),
                },
                {
                    title: geti18nText('device.model.table.active'),
                    dataIndex: 'active',
                    width: '10%',
                    render: (text: any, record: { active: any }) => {
                        if (record.active) {
                            return <CheckOutlined style={{ color: 'green' }} />;
                        } else {
                            return <CloseOutlined style={{ color: 'red' }} />;
                        }
                    },
                    ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', 1),
                },
            ]}
        />
    );
};

export default AdminDeviceModelIndex;
