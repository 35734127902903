import { geti18nText, NyRequestResolver, NySpinner, NyUtils, RESPONSE } from '@nybble/nyreact';
import { Button, DatePicker, Form, message, Modal, notification } from 'antd';
import { useEffect, useState } from 'react';
import { CONSTANTS_REQ } from '../../utils/Constants';

const HnbReportsEdit = (props: any) => {
    const [form] = Form.useForm();
    const [date, setDate] = useState<any>(null);
    const [period, setPeriod] = useState<any>(props.addedData?.period);
    const [laoding, setLoading] = useState(false);

    useEffect(() => {
        console.log(props);
    }, []);
    function onCancelCountDetailsVisible() {
        props.setVisible(false);
    }

    function onChange(date: any, dateString: any) {
        setDate(dateString);
    }

    function onOk() {
        setLoading(true);
        NyRequestResolver.requestPost(
            period == 'M' ? CONSTANTS_REQ.HNB_REPORT.REPORT_MONTHLY : CONSTANTS_REQ.HNB_REPORT.REPORT_DAILY,
            undefined,
            { date: date },
            false,
            true
        ).then((result: any) => {
            setLoading(false);
            if (result && result.status === RESPONSE.OK) {
                props.onSaveAndGetID();
                props.setVisible(false);
            } else {
                notification['error']({
                    message: geti18nText('app.default.error'),
                    description: message,
                    duration: 0,
                });
            }
        });
    }
    return (
        <Modal
            visible={props.visible}
            onCancel={onCancelCountDetailsVisible}
            destroyOnClose={true}
            title={geti18nText('hnbReport.edit.title')}
            width={700}
            footer={[
                <Button key="back" onClick={onCancelCountDetailsVisible}>
                    {geti18nText('app.default.button.cancel')}
                </Button>,
                <Button type="primary" onClick={onOk}>
                    {geti18nText('hnbReport.edit.okButton')}
                </Button>,
            ]}
        >
            {laoding ? (
                <NySpinner></NySpinner>
            ) : (
                <Form name="basic" labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} form={form}>
                    <Form.Item
                        label={geti18nText('hnbReport.edit.date')}
                        name="date"
                        rules={[{ required: true, message: geti18nText('app.default.required') }]}
                    >
                        {period == 'M' ? (
                            <DatePicker picker="month" onChange={onChange} style={{ width: 200 }} />
                        ) : (
                            <DatePicker onChange={onChange} style={{ width: 200 }} />
                        )}
                    </Form.Item>
                </Form>
            )}
        </Modal>
    );
};

export default HnbReportsEdit;
