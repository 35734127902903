import { DownloadOutlined } from '@ant-design/icons';
import {
    geti18nText,
    NyDataEdit,
    NySession,
    NyMaskContent,
    NyRequestResolver,
    RESPONSE,
    NyDataTable,
    getColumnDateOption,
    NyUtils,
} from '@nybble/nyreact';
import {
    Col,
    Form,
    Input,
    Row,
    Tabs,
    Image,
    Descriptions,
    Tooltip,
    Button,
    Modal,
    InputNumber,
    notification,
    Checkbox,
    Collapse,
    DatePicker,
    TimePicker,
} from 'antd';
import L from 'leaflet';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import { getTimezone } from '../../../../utils/Utils';
import LogoSettings from '../logo_settings';
import HnbReportSettings from '../hnb_report_settings';
const { Panel } = Collapse;
const MailSettingsEdit = (props: any) => {
    const [editHeader, setEditHeader] = useState(geti18nText('office.edit.new'));
    const [loading, setLoading] = useState(false);
    const { id } = useParams<any>();
    const history = useHistory();
    const [form] = Form.useForm();
    const [formDevice] = Form.useForm();
    const { TabPane } = Tabs;
    const [isCreated, setIsCreated] = useState(false);
    const [emailTest, setEmailTest] = useState(false);
    const [settingsRole, setSettingsRole] = useState(false);

    useEffect(() => {
        fetch();
        fetchDevicesSettings();
        setSettingsRole(NySession.hasAnyRole(['RULE_SETTINGS_CRUD']));
    }, []);

    const onModalClose = () => {
        form.resetFields();
    };
    function setValues(dataForm: any) {
        form.setFieldsValue(dataForm);
    }

    function setValuesForDevices(dataForm: any) {
        if (dataForm.deviceWorkTime) {
            dataForm.deviceWorkTime = moment(dataForm.deviceWorkTime, 'HH:mm');
        }
        formDevice.setFieldsValue(dataForm);
    }
    const onFinish = (values: any) => {
        if (isCreated) {
            NyRequestResolver.requestPut(CONSTANTS_REQ.SETTINGS.MAIL + '/' + values.id, undefined, {
                ...values,
                id: values.id,
            }).then((result) => {
                if (result && result.status === RESPONSE.CREATED) {
                    notification.success({
                        message: geti18nText('app.default.save.ok'),
                        description: geti18nText('app.default.save.ok.desc'),
                        duration: 3,
                    });
                }
            });
        } else {
            NyRequestResolver.requestPost(CONSTANTS_REQ.SETTINGS.MAIL, undefined, {
                ...values,
            }).then((result) => {
                if (result && result.status === RESPONSE.CREATED) {
                    notification.success({
                        message: geti18nText('app.default.save.ok'),
                        description: geti18nText('app.default.save.ok.desc'),
                        duration: 3,
                    });
                }
            });
        }
    };

    const setDevicesWorkTime = (values: any) => {
        values.deviceWorkTime = moment(values.deviceWorkTime).format('HH:mm');
        NyRequestResolver.requestPost(CONSTANTS_REQ.SETTINGS.DEVICES_WORK_TIME, undefined, {
            ...values,
        }).then((result: any) => {
            if (result && result.status === RESPONSE.CREATED) {
                notification.success({
                    message: geti18nText('app.default.save.ok'),
                    description: geti18nText('app.default.save.ok.desc'),
                    duration: 4,
                });
            } else {
                notification.error({
                    message: geti18nText('app.default.save.nok'),
                    description: result.data?.message,
                    duration: 5,
                });
            }
        });
    };

    const fetch = () => {
        NyRequestResolver.requestGet(CONSTANTS_REQ.SETTINGS.MAIL).then((result) => {
            if (result.status === RESPONSE.OK) {
                if (result.data != null) {
                    setIsCreated(true);
                    setValues(result.data);
                }
            }
            setLoading(false);
        });
    };

    const fetchDevicesSettings = () => {
        NyRequestResolver.requestGet(CONSTANTS_REQ.SETTINGS.DEVICES_WORK_TIME).then((result: any) => {
            if (result.status === RESPONSE.OK) {
                if (result.data != null) {
                    //  setIsCreated(true);
                    setValuesForDevices(result.data);
                }
            }
            setLoading(false);
        });
    };

    const testMail = () => {
        const values = form.getFieldsValue();
        setEmailTest(true);
        NyRequestResolver.requestPost(CONSTANTS_REQ.SETTINGS.TEST_MAIL, undefined, { ...values }).then(
            (result: any) => {
                setEmailTest(false);
                if (result.status === RESPONSE.OK) {
                    notification.success({
                        message: geti18nText('app.default.save.ok'),
                        duration: 3,
                    });
                } else {
                    notification.error({
                        message: geti18nText('app.default.save.nok'),
                        description: result.data?.message,
                        duration: 5,
                    });
                }
                setLoading(false);
            }
        );
    };

    function downloadFile(result: any) {
        NyUtils.downloadFile(result);
    }

    function onRowSelect(record: any) {
        exportData(record);
    }

    function okNotification(message: any) {
        notification['success']({
            message: message,
            duration: 10,
        });
    }

    function error(message: any) {
        notification['error']({
            message: geti18nText('reports.download.nok'),
            description: message,
            duration: 0,
        });
    }

    function exportData(data: any) {
        setLoading(true);
        NyRequestResolver.requestGet(CONSTANTS_REQ.LOGS.DOWNLOADLOGFILE, { name: data.name }, false, true).then(
            (result: any) => {
                setLoading(false);
                if (result && result.status === RESPONSE.OK) {
                    result.data.filename = data.name;
                    downloadFile(result);
                    setLoading(false);
                    okNotification(geti18nText('currency.denomination.export.OK'));
                } else {
                    console.log(JSON.stringify(result.data));
                    error(JSON.stringify(result.data));
                }
            }
        );
    }

    return (
        <Tabs defaultActiveKey="1" type="card">
            {settingsRole && (
                <TabPane tab={geti18nText('settings.tab.mail')} key="1">
                    <Form form={form} onFinish={onFinish} labelCol={{ span: 7 }}>
                        <Row gutter={24}>
                            <Form.Item name="id" style={{ display: 'none' }}>
                                <Input readOnly={true} />
                            </Form.Item>
                            <Col span={8}>
                                <Form.Item
                                    rules={[
                                        {
                                            required: true,
                                            message: geti18nText('app.default.required'),
                                        },
                                    ]}
                                    label={geti18nText('settings.mail.host')}
                                    name="host"
                                >
                                    <Input disabled={emailTest} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    rules={[
                                        {
                                            required: true,
                                            message: geti18nText('app.default.required'),
                                        },
                                    ]}
                                    label={geti18nText('settings.mail.port')}
                                    name="port"
                                >
                                    <InputNumber min={1} disabled={emailTest} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={8}>
                                <Form.Item label={geti18nText('settings.mail.username')} name="username">
                                    <Input disabled={emailTest} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label={geti18nText('settings.mail.password')} name="password">
                                    <Input type="password" disabled={emailTest} />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={24}>
                            <Col span={8}>
                                <Form.Item
                                    rules={[
                                        {
                                            required: true,
                                            message: geti18nText('settings.mail.sender'),
                                        },
                                    ]}
                                    label={geti18nText('settings.mail.sender')}
                                    name="sender"
                                >
                                    <Input disabled={emailTest} type="email" />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Collapse collapsible="header" defaultActiveKey={'1'}>
                            <Panel header={geti18nText('settings.mail.collapse.title')} key="1">
                                {/* ostale postavke  */}
                                <Row>
                                    <Col span={6}>
                                        <Form.Item name="smtpAuth" valuePropName="checked" noStyle>
                                            <Checkbox disabled={emailTest}>
                                                {geti18nText('settings.mail.smtp_auth')}
                                            </Checkbox>
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item name="startTls" valuePropName="checked" noStyle>
                                            <Checkbox disabled={emailTest}>
                                                {geti18nText('settings.mail.starttls')}
                                            </Checkbox>
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item name="sslConnection" valuePropName="checked" noStyle>
                                            <Checkbox disabled={emailTest}>{geti18nText('settings.mail.ssl')}</Checkbox>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: '20px' }}>
                                    <Col span={6}>
                                        <Form.Item name="socketFactoryFallback" valuePropName="checked" noStyle>
                                            <Checkbox disabled={emailTest}>
                                                {geti18nText('settings.mail.socket_factor_fallback')}
                                            </Checkbox>
                                        </Form.Item>
                                    </Col>

                                    <Col span={8}>
                                        <Form.Item
                                            labelAlign="left"
                                            label={geti18nText('settings.mail.socket_factor_port')}
                                            name="socketFactoryPort"
                                        >
                                            <InputNumber min={1} disabled={emailTest} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={10} style={{ textAlign: 'left' }}>
                                        <Form.Item
                                            labelAlign="left"
                                            label={geti18nText('settings.mail.socket_factor_class')}
                                            name="socketFactoryClass"
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Panel>
                        </Collapse>

                        <Row gutter={8}>
                            <Row style={{ textAlign: 'left', marginTop: '20px', marginLeft: '20px' }}>
                                <Form.Item style={{ marginRight: '20px' }}>
                                    <Button type="primary" htmlType="submit">
                                        {geti18nText('settings.mail.btn.save')}
                                    </Button>
                                </Form.Item>
                                <Form.Item>
                                    <Button type="default" onClick={testMail}>
                                        {geti18nText('settings.mail.btn.test')}
                                    </Button>
                                </Form.Item>
                            </Row>
                        </Row>
                    </Form>
                </TabPane>
            )}
            {(NySession.getSetting('log')['save-to-file'] === true ||
                NySession.getSetting('log')['save-to-file'] === 'true') &&
                NySession.hasAnyRole(['RULE_LOG_PREVIEW']) && (
                    <TabPane tab={geti18nText('settings.tab.logs')} key="2">
                        <NyDataTable
                            headerTitle={geti18nText('log.table.header')}
                            url={CONSTANTS_REQ.LOGS.LIST}
                            showRecordModal={false}
                            setDefaultPageSize={20}
                            hideButtons={true}
                            onRowSelect={onRowSelect}
                            hidePagination={true}
                            columns={[
                                {
                                    title: geti18nText('log.table.column.date'),
                                    dataIndex: 'date',
                                    width: '2%',
                                    render: (text: string, record: { [index: string]: any }) => {
                                        if (record.second) {
                                            return (
                                                <div>
                                                    {new Date(record.second).toLocaleString(
                                                        NyUtils.getSelectedLocale(),
                                                        getTimezone()
                                                    )}
                                                </div>
                                            );
                                        }
                                    },
                                },
                                {
                                    title: geti18nText('log.table.column.name'),
                                    dataIndex: 'name',
                                    width: '2%',
                                    align: 'left',
                                },
                                {
                                    dataIndex: 'download',
                                    width: '10%',
                                    render: (text: string, record: { [index: string]: any }) => {
                                        return (
                                            <div>
                                                <Button
                                                    type="primary"
                                                    onClick={() => {
                                                        onRowSelect(record);
                                                    }}
                                                    icon={<DownloadOutlined />}
                                                ></Button>
                                            </div>
                                        );
                                    },
                                },
                            ]}
                        />
                    </TabPane>
                )}
            {settingsRole && (
                <TabPane tab={geti18nText('settings.tab.work.time')} key="3">
                    <Form form={formDevice} onFinish={setDevicesWorkTime} labelCol={{ span: 7 }}>
                        <Row gutter={24}>
                            <Form.Item name="id" style={{ display: 'none' }}>
                                <Input readOnly={true} />
                            </Form.Item>
                            <Col span={2}></Col>
                            <Col span={20}>
                                <Form.Item label={geti18nText('settings.device.work.time')} name="deviceWorkTime">
                                    <TimePicker format={'HH:mm'}></TimePicker>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={2}></Col>
                            <Col span={20}>
                                <Form.Item style={{ marginLeft: '29%' }}>
                                    <Button type="primary" htmlType="submit">
                                        {geti18nText('settings.work.btn.save')}
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </TabPane>
            )}
            {settingsRole && (
                <TabPane tab={geti18nText('settings.tab.app.settings')} key="4">
                    <LogoSettings></LogoSettings>
                </TabPane>
            )}
            {settingsRole && (
                <TabPane tab={geti18nText('settings.tab.hnbReport')} key="5">
                    <HnbReportSettings></HnbReportSettings>
                </TabPane>
            )}
        </Tabs>
    );
};

export default MailSettingsEdit;
