import { getColumnSearch, geti18nText, NyDataTable } from '@nybble/nyreact';
import { CONSTANTS_REQ } from '../../utils/Constants';
import { useEffect } from 'react';

const DeviceSoftwareIndex = (props: any) => {
    useEffect(() => {
        console.log('props .. ', props);
    }, []);
    function setDefaultFilterValue() {
        return [];
    }

    return (
        <NyDataTable
            url={CONSTANTS_REQ.DEVICE_SOFTWARE.LIST}
            headerTitle={geti18nText('device.edit.tab.software.title')}
            showRecordModal={false}
            hideButtons={true}
            setDefaultPageSize={10}
            exportCSV={true}
            addedData={{ deviceId: props.deviceId }}
            setDefaultFilterValue={setDefaultFilterValue}
            columns={[
                {
                    title: geti18nText('device.edit.tab.software.table.id'),
                    dataIndex: 'id',
                    width: '10%',
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('number'),
                },
                {
                    title: geti18nText('device.edit.tab.software.table.module'),
                    dataIndex: 'module',
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('string'),
                },
                {
                    title: geti18nText('device.edit.tab.software.table.firmware'),
                    dataIndex: 'firmware',
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('string'),
                },
                {
                    title: geti18nText('device.edit.tab.software.table.revision'),
                    dataIndex: 'revision',
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('string'),
                },
            ]}
        />
    );
};

export default DeviceSoftwareIndex;
