import Settings2FAIndex from '../../views/2FASettings';
import AdminCurrencyIndex from '../../views/admin/currency';
import AdminDeviceIndex from '../../views/admin/device';
import AdminDeviceModelIndex from '../../views/admin/device-model';
import AdminDeviceGroupsIndex from '../../views/admin/device_groups';
import AdminDeviceLogIndex from '../../views/admin/downloadDeviceLog';
import AdminFirmwareFilesIndex from '../../views/admin/firmware/files';
import AdminFirmwareUpdateIndex from '../../views/admin/firmware/update';
import HnbDeviceTypeIndex from '../../views/admin/hnb-device-type';
import AdminInstancesIndex from '../../views/admin/instances';
import AdminManufacturerIndex from '../../views/admin/manufacturer';
import AdminNotificationAlarmIndex from '../../views/admin/notification-alarm';
import AdminLocationIndex from '../../views/admin/office';
import AdminServiceInformationIndex from '../../views/admin/service-information';
import AdminServiceVendorIndex from '../../views/admin/service-vendor';
import MailSettingsEdit from '../../views/admin/settings/email/email_settings';
import AdminVendorIndex from '../../views/admin/vendor';
import CertManagment from '../../views/cert';
import CompanyIndex from '../../views/company';
import Dashboard from '../../views/dashboard/Dashboard';
import DeviceIndex from '../../views/device';
import HnbSnapshotHistoryIndex from '../../views/hnb/snapshot';
import HnbReportsMainIndex from '../../views/hnbReports/mainIndex';
import AllCountSerialIndex from '../../views/serial/index_all';
import DeviceCountSerialIndex from '../../views/serial/index_device';
import MasterDataIndex from '../../views/statistic/master_data/MasterData';
import StatisticChartIndex from '../../views/statistic/StatisticIndex';
import UserIndex from '../../views/user';
import UserGroupIndex from '../../views/user_group';
import UserRoleIndex from '../../views/user_role';

export const mainRoutes = [
    {
        path: '/',
        exact: true,
        component: Dashboard,
        role: ['RULE_PREVIEW_DEVICE'],
    },
    {
        path: '/device',
        exact: true,
        component: DeviceIndex,
        role: ['RULE_PREVIEW_DEVICE'],
    },
    {
        path: '/admin/device',
        exact: true,
        component: AdminDeviceIndex,
        role: ['RULE_PREVIEW_DEVICE'],
    },
    {
        path: '/admin/user',
        exact: true,
        component: UserIndex,
        role: ['RULE_PREVIEW_USER'],
    },
    {
        path: '/admin/role',
        exact: true,
        component: UserRoleIndex,
        role: ['RULE_PREVIEW_USER_ROLE'],
    },

    {
        path: '/admin/user_group',
        exact: true,
        component: UserGroupIndex,
        role: ['RULE_PREVIEW_USER_GROUP'],
    },
    {
        path: '/admin/company',
        exact: true,
        component: CompanyIndex,
        role: ['RULE_COMPANY_PREVIEW'],
    },
    {
        path: '/2fa',
        exact: true,
        component: Settings2FAIndex,
        role: ['RULE_UPDATE_USER'],
    },
    {
        path: '/admin/office',
        exact: true,
        component: AdminLocationIndex,
        role: ['RULE_BRANCH_PREVIEW'],
    },
    {
        path: '/admin/deviceGroups',
        exact: true,
        component: AdminDeviceGroupsIndex,
        role: ['RULE_PREVIEW_DEVICE_GROUP'],
    },
    {
        path: '/admin/notification',
        exact: true,
        component: AdminNotificationAlarmIndex,
        role: ['RULE_PREVIEW_NOTIFICATION'],
    },
    {
        path: '/admin/manufacturer',
        exact: true,
        component: AdminManufacturerIndex,
        role: ['RULE_PREVIEW_D_MANUFACTURE'],
    },
    {
        path: '/admin/vendor',
        exact: true,
        component: AdminVendorIndex,
        role: ['RULE_PREVIEW_VENDOR'],
    },
    {
        path: '/admin/service-vendor',
        exact: true,
        component: AdminServiceVendorIndex,
        role: ['RULE_PREVIEW_SERVICE_VENDOR'],
    },
    {
        path: '/admin/currency',
        exact: true,
        component: AdminCurrencyIndex,
        role: ['RULE_CURRENCY_CRUD', 'RULE_CURRENCY_PREVIEW'],
    },
    {
        path: '/admin/deviceModel',
        exact: true,
        component: AdminDeviceModelIndex,
        role: ['RULE_DEVICE_MODEL_PREVIEW'],
    },
    {
        path: '/admin/instances',
        exact: true,
        component: AdminInstancesIndex,
        role: ['RULE_INSTANCE_PREVIEW'],
    },
    {
        path: '/reports/statistic',
        exact: true,
        component: StatisticChartIndex,
        role: ['RULE_PREVIEW_STATISTIC'],
    },
    {
        path: '/reports/hnbReports',
        exact: true,
        component: HnbReportsMainIndex,
        role: ['RULE_HNB_REPORT_PREVIEW'],
    },
    {
        path: '/hnb_snapshot',
        exact: true,
        component: HnbSnapshotHistoryIndex,
        role: ['RULE_UPDATE_MANUAL_COUNT'],
    },
    {
        path: '/admin/settings',
        exact: true,
        component: MailSettingsEdit,
        role: ['RULE_SETTINGS_CRUD', 'RULE_LOG_PREVIEW'],
    },
    {
        path: '/reports/master_data',
        exact: true,
        component: MasterDataIndex,
        role: ['RULE_PREVIEW_DEVICE'],
    },
    {
        path: '/admin/serviceInformation',
        exact: true,
        component: AdminServiceInformationIndex,
        role: ['RULE_EXPORT_IMPORT_SERVICE_INFO'],
    },
    {
        path: '/admin/cert',
        exact: true,
        component: CertManagment,
        role: ['RULE_CERTIFICATE_UPLOAD', 'RULE_CERTIFICATE_UPLOAD_INFO_ONLY'],
    },
    {
        path: '/admin/firmaware-files',
        exact: true,
        component: AdminFirmwareFilesIndex,
        role: ['RULE_PREVIEW_FIRMWARE_FILES'],
    },
    {
        path: '/admin/firmaware-update',
        exact: true,
        component: AdminFirmwareUpdateIndex,
        role: ['RULE_UPDATE_FIRMWARE'],
    },
    {
        path: '/admin/device-logs',
        exact: true,
        component: AdminDeviceLogIndex,
        role: ['RULE_DEVICE_LOG_PREVIEW'],
    },
    {
        path: '/admin/hnbDeviceType',
        exact: true,
        component: HnbDeviceTypeIndex,
        role: ['RULE_PREVIEW_DEVICE'],
    },
    {
        path: '/admin/serial',
        exact: true,
        component: AllCountSerialIndex,
        role: ['RULE_PREVIEW_DEVICE'],
    },
];
