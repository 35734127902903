import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import {
    ENUMS,
    getColumnSearch,
    getColumnSearchOption,
    geti18nText,
    NyDataEdit,
    NyDataTable,
    NySession,
    NyUtils,
} from '@nybble/nyreact';
import { Col, Form, Input, InputNumber, Row, Tabs } from 'antd';
import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { CONSTANTS_REQ } from '../../../utils/Constants';
import { GetEnum, GetEnumNameForValue } from '../../../utils/Enums';
import AdminDenominationCurrencyEdit from './denominationEdit';

const AdminCurrencyEdit = (props: any) => {
    const [editHeader, setEditHeader] = useState(geti18nText('currency.add.new'));
    const [loading, setLoading] = useState(false);
    const [idCurrency, setIdCurrency] = useState(null);
    const { id } = useParams<any>();
    const history = useHistory();
    const [form] = Form.useForm();
    const { TabPane } = Tabs;
    const [isCreate, setIsCreate] = useState(false);

    const onModalClose = () => {
        setEditHeader(geti18nText('currency.add.new'));
        form.resetFields();
    };
    function setValues(dataForm: any) {
        setEditHeader(dataForm.currencyCode);
        setIdCurrency(dataForm.id);
        form.setFieldsValue(dataForm);
    }

    function setDefaultFilterValue() {
        return [
            { field: 'active', condition: 'equals_bool', value: 1 },
            { field: 'currency.id', condition: 'equals', value: idCurrency },
        ];
    }

    const currencyType = () => {
        const aTypes = GetEnum({ enumName: 'CURRENCY_TYPE' });
        let types = [];
        for (var key in aTypes) {
            let ret = { id: undefined, text: '' };
            ret.id = aTypes[key];
            ret.text = geti18nText('currency.type.' + key.toLowerCase());
            types.push(ret);
        }

        return types;
    };

    function onSaveAndGetData(data: any) {
        const currency: [any] = NyUtils.load('CURRENCY');
        if (data.active === true) {
            let index = currency.findIndex((el) => el.id === data.id);
            if (index != -1) currency.splice(index, 1);
            currency.push({
                id: data.id,
                active: true,
                currencyCode: data.currencyCode,
                isoCode: data.isoCode,
                precision: data.precision ? data.precision : 2,
            });
        } else {
            let index = currency.findIndex((el) => el.id === data.id);
            if (index != -1) currency.splice(index, 1);
        }
        NyUtils.save('CURRENCY', currency ? currency : []);
        props.onSaveAndGetData(data);
    }

    return (
        <NyDataEdit
            layout="horizontal"
            formProps={{ labelCol: { span: 8 }, wrapperCol: { span: 15 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            url={CONSTANTS_REQ.CURRENCY.EDIT}
            setValues={setValues}
            width={1000}
            form={form}
            setIsCreate={setIsCreate}
            goBack={() => history.goBack()}
            paramsId={id}
            {...props}
            hideButtons={!NySession.hasAnyRole(['RULE_CRUD_BRANCH'])}
            onSaveAndGetData={onSaveAndGetData}
    
        >
            <Tabs defaultActiveKey="1" type="card">
                <TabPane tab={geti18nText('currency.edit.tab.info')} key="1">
                    <Row gutter={24}>
                        <Form.Item name="id" style={{ display: 'none' }}>
                            <Input readOnly={true} />
                        </Form.Item>
                        <Col span={12}>
                            <Form.Item
                                rules={[
                                    {
                                        required: true,
                                        message: geti18nText('app.default.required'),
                                    },
                                ]}
                                label={geti18nText('currency.table.column.currency.code')}
                                name="currencyCode"
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                rules={[
                                    {
                                        pattern: new RegExp('[0-9]'),
                                    },

                                    { min: 3, message: geti18nText('currency.edit.min.iso.code') },
                                ]}
                                label={geti18nText('currency.table.column.iso.code')}
                                name="isoCode"
                            >
                                <Input maxLength={3} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Col span={12}>
                        <Form.Item
                            rules={[
                                {
                                    required: false,
                                    type: 'regexp',
                                    pattern: new RegExp(/\d+/g),
                                    message: geti18nText('device.edit.settingsCalculateApoen.wrong.format'),
                                },
                            ]}
                            label={geti18nText('currency.edit.precision')}
                            name="precision"
                        >
                            <InputNumber type="number" />
                        </Form.Item>
                    </Col>

                    {/* <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                            rules={[
                                {
                                    required: true,
                                    message: geti18nText('app.default.required'),
                                },
                            ]}
                            label={geti18nText('currency.table.column.ratio')} name="ratio">
                                <InputNumber  decimalSeparator="." />
                            </Form.Item>
                        </Col>
                    </Row> */}
                </TabPane>
                <TabPane
                    tab={geti18nText('currency.edit.tab.currency.denominations')}
                    disabled={isCreate || loading}
                    key="2"
                >
                    <NyDataTable
                        headerTitle={geti18nText('currency.denomination.table.header')}
                        url={CONSTANTS_REQ.CURRENCY_DENOMINATIONS.LIST}
                        showRecordModal={true}
                        addNewButtonText={geti18nText('currency.denomination.add.new')}
                        modalComponent={AdminDenominationCurrencyEdit}
                        setDefaultPageSize={20}
                        hideButtons={!NySession.hasAnyRole(['RULE_CRUD_BRANCH'])}
                        exportCSV={true}
                        addedData={{ currency: { id: idCurrency } }}
                        setDefaultFilterValue={setDefaultFilterValue}
                        columns={[
                            {
                                title: geti18nText('currency.denomination.table.column.id'),
                                dataIndex: 'id',
                                width: '10%',
                                sorter: (a: any, b: any) => {},
                                ...getColumnSearch('number'),
                            },
                            {
                                title: geti18nText('currency.denomination.table.column.currency'),
                                dataIndex: ['currency'],
                                sorter: (a: any, b: any) => {},
                            },
                            {
                                title: geti18nText('currency.denomination.table.column.value'),
                                dataIndex: 'value',
                                sorter: (a: any, b: any) => {},
                                ...getColumnSearch('string'),
                            },
                            {
                                title: geti18nText('currency.denomination.table.column.type'),
                                dataIndex: 'type',
                                sorter: (a: any, b: any) => {},
                                ...getColumnSearch('string'),
                                render: (text: string, record: { [index: string]: any }) => {
                                    let type = GetEnumNameForValue({
                                        enumName: 'CURRENCY_TYPE',
                                        value: record.type,
                                    })?.toLowerCase();
                                    return <div>{geti18nText('currency.type.' + type)}</div>;
                                },
                                ...getColumnSearchOption(currencyType(), 'equals'),
                            },

                            {
                                title: geti18nText('office.table.column.active'),
                                dataIndex: 'active',
                                width: '10%',
                                render: (text: any, record: { active: any }) => {
                                    if (record.active) {
                                        return <CheckOutlined style={{ color: 'green' }} />;
                                    } else {
                                        return <CloseOutlined style={{ color: 'red' }} />;
                                    }
                                },
                                ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', 1),
                            },
                        ]}
                    />
                </TabPane>
            </Tabs>
        </NyDataEdit>
    );
};

export default AdminCurrencyEdit;
