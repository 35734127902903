import { geti18nText, NyUtils } from '@nybble/nyreact';
import { Alert, Descriptions, Progress, Typography } from 'antd';
import { useEffect, useState } from 'react';

import { getTimezone } from '../../../utils/Utils';
import LicenseTypePreviewTable from './table';
const { Text } = Typography;
const LicenseTypePreview = ({ data }: any) => {
    const [tableData, setTableData] = useState<any>();
    useEffect(() => {
        setData();
    }, []);

    function setData() {
        if (data != undefined && data.licenseTypes) {
            setTableData(data.licenseTypes);
        }
    }

    return (
        <>
            <Descriptions
                column={1}
                bordered
                size="small"
                style={{ color: 'black' }}
                className={'ant-typography-print-title'}
            >
                <Descriptions.Item label={geti18nText('monitor.license.preview.license.fileName')}>
                    <Text>{data?.fileName}</Text>
                </Descriptions.Item>
                <Descriptions.Item label={geti18nText('monitor.license.preview.guid')}>
                    <Text>{data?.guid}</Text>
                </Descriptions.Item>
                <Descriptions.Item label={geti18nText('monitor.license.preview.license.isValid')}>
                    <Text>
                        {data?.isValid == true ? (
                            <Alert message={geti18nText('monitor.license.preview.license.valid')} type="success" />
                        ) : (
                            <Alert message={geti18nText('monitor.license.preview.license.not.valid')} type="error" />
                        )}
                    </Text>
                </Descriptions.Item>
                <Descriptions.Item label={geti18nText('monitor.license.preview.validUntil')}>
                    {data.validTo && (
                        <Text>
                            {new Date(data.validTo[0], data.validTo[1] - 1, data.validTo[2]).toLocaleDateString(
                                NyUtils.getSelectedLocale(),
                                getTimezone()
                            )}
                        </Text>
                    )}
                </Descriptions.Item>
                <Descriptions.Item label={geti18nText('monitor.license.preview.type.licenseType')}>
                    <Text>
                        {data?.isEnterprise
                            ? geti18nText('monitor.license.preview.type.enterprise')
                            : geti18nText('monitor.license.preview.type.custom')}
                    </Text>
                </Descriptions.Item>
                {data?.isEnterprise == false && (
                    <Descriptions.Item label={geti18nText('monitor.license.preview.licenseNumbers')}>
                        <LicenseTypePreviewTable dataSource={tableData} showInfo={true} />
                    </Descriptions.Item>
                )}
            </Descriptions>
        </>
    );
};

export default LicenseTypePreview;
