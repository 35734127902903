import {
    ApiOutlined,
    CheckOutlined,
    CloseOutlined,
    CommentOutlined,
    PoweroffOutlined,
    UserOutlined,
} from '@ant-design/icons';
import {
    ENUMS,
    getColumnDateOption,
    getColumnSearch,
    getColumnSearchOption,
    geti18nText,
    NyRequestResolver,
    NySession,
    NyUtils,
    RESPONSE,
} from '@nybble/nyreact';
import { Comment, Popover, Tag, Tooltip, Typography } from 'antd';
import React, { useState } from 'react';
import { CONSTANTS_REQ } from '../../../utils/Constants';
import { GetEnum } from '../../../utils/Enums';
import { getTimezone } from '../../../utils/Utils';

const deviceModels = () => {
    const models = NyUtils.load('DEVICE_MODELS');
    let types: any = [];
    models.forEach((element: any) => {
        let ret: any = {};
        ret.id = element.id;
        ret.text = element.name;
        types.push(ret);
    });
    return types;
};

const deviceStatus = (status = undefined) => {
    const aTypes = GetEnum({ enumName: 'DEVICE_STATUS' });
    let types = [];
    for (var key in aTypes) {
        if (aTypes.hasOwnProperty(key)) {
            let ret = { id: undefined, text: '' };

            ret.id = aTypes[key];
            ret.text = geti18nText('device.status.enum.' + key);
            if (status == null || status == aTypes[key]) {
                types.push(ret);
            }
        }
    }
    return types;
};

const manufacturers = () => {
    const manufacturers = NyUtils.load('MANUFACTURERS');
    let types: any = [];
    manufacturers.forEach((element: any) => {
        let ret: any = {};
        ret.id = element.id;
        ret.text = element.name;
        types.push(ret);
    });

    return types;
};

const offices = () => {
    const manufacturers = NyUtils.load('OFFICE');
    let types: any = [];
    manufacturers.forEach((element: any) => {
        let ret: any = {};
        ret.id = element.id;
        ret.text = element.name;
        types.push(ret);
    });

    return types;
};

const groups = (groupIds: any = undefined) => {
    const groups = NyUtils.load('DEVICE_GROUPS');
    let types: any = [];
    groups.forEach((element: any) => {
        let ret: any = {};
        ret.id = element.id;
        ret.text = element.name;
        if (groupIds == null || groupIds.includes(element.id.toString())) {
            types.push(ret);
        }
    });

    return types;
};

function content(text: any) {
    let contentArray = text.split(',');
    return contentArray.map((c: any) => {
        return <div>{c}</div>;
    });
}

const deviceMode = () => {
    const aTypes = GetEnum({ enumName: 'DEVICE_MODE' });
    let types = [];
    for (var key in aTypes) {
        if (aTypes.hasOwnProperty(key)) {
            let ret: any = {};
            ret.id = aTypes[key];
            ret.text = key;
            types.push(ret);
        }
    }
    return types;
};
const deviceConnctionType = () => {
    const aTypes = GetEnum({ enumName: 'DEVICE_CONNECTION_TYPE' });
    let types = [];
    for (var key in aTypes) {
        if (aTypes.hasOwnProperty(key)) {
            let ret: any = {};
            ret.id = aTypes[key];
            ret.text = key;
            types.push(ret);
        }
    }
    return types;
};

const licenseTypes = () => {
    const aTypes = GetEnum({ enumName: 'LICENSE_TYPES' });
    let types = [];
    for (var key in aTypes) {
        if (aTypes.hasOwnProperty(key)) {
            let ret: any = {};
            ret.id = key;
            ret.text = key;
            types.push(ret);
        }
    }
    return types;
};

const isEnterpreise = () => {
    const aTypes = GetEnum({ enumName: 'LICENSE_TYPES' });
    for (var key in aTypes) {
        if (key == 'ENTERPRISE') {
            return true;
        }
    }
    return false;
};

const WRITTEN_OFF = () => {
    let types: any = [];
    types.push(
        { id: 1, text: geti18nText('device.table.column.written.off') },
        { id: 0, text: geti18nText('device.table.column.written.off.false') }
    );
    return types;
};
const CORRECT = () => {
    let types: any = [];
    types.push(
        { id: 1, text: geti18nText('device.table.column.valid') },
        { id: 0, text: geti18nText('device.table.column.invalid') }
    );
    return types;
};
const REPAIR = () => {
    let types: any = [];
    types.push(
        { id: 1, text: geti18nText('device.table.column.repair') },
        { id: 0, text: geti18nText('device.table.column.not.repair') }
    );
    return types;
};

const { Text } = Typography;

export const CommentComponent = (props: any) => {
    const [commentContent, setCommentContent] = useState([]);
    function deviceComment(deviceId: bigint) {
        NyRequestResolver.requestGet(CONSTANTS_REQ.REFERENCES.LIST_BY_DEVICE, { deviceId: deviceId, max: 3 }).then(
            (response: any) => {
                if (response.status === RESPONSE.OK) {
                    setCommentContent(response.data.content);
                }
            }
        );
    }
    return (
        <React.Fragment>
            <Popover
                content={
                    <React.Fragment>
                        {commentContent &&
                            commentContent.map((item: { [index: string]: any }) => (
                                <React.Fragment>
                                    <Comment
                                        author={item.username}
                                        avatar={<UserOutlined />}
                                        content={content(item.text)}
                                        datetime={new Date(item.created).toLocaleString(
                                            NyUtils.getSelectedLocale(),
                                            getTimezone()
                                        )}
                                    />
                                </React.Fragment>
                            ))}
                    </React.Fragment>
                }
                title={geti18nText('device.edit.tab.reference')}
            >
                <CommentOutlined
                    style={{ fontSize: '14px', color: '#3498DB' }}
                    onMouseOver={() => deviceComment(props.id)}
                />
            </Popover>
        </React.Fragment>
    );
};

const enumUseParser = () => {
    return [
        { id: 1, text: geti18nText('device.table.use.parser.true') },
        { id: 0, text: geti18nText('device.table.use.parser.false') },
    ];
};

function DeviceColumns(props?: any, status = undefined, groupIds = undefined) {
    let columns: any = [
        {
            dataIndex: 'status',
            ...getColumnSearchOption(deviceStatus(status)),
            render: (text: string, record: { [index: string]: any }) => {
                return record.useParser && record.useParser == true ? (
                    <div>
                        {record.status == 0 ? (
                            <PoweroffOutlined style={{ color: 'green', marginRight: '5px' }} />
                        ) : record.status == 1 ? (
                            <PoweroffOutlined className="Blink" style={{ color: 'red', marginRight: '5px' }} />
                        ) : (
                            <PoweroffOutlined style={{ color: 'orange', marginRight: '5px' }} />
                        )}
                    </div>
                ) : (
                    <></>
                );
            },
        },
        {
            title: geti18nText('device.table.column.id'),
            dataIndex: 'id',
            width: '5%',

            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
            render: (text: string, record: { [index: string]: any }) => {
                return <div>{record.id}</div>;
            },
        },
        {
            dataIndex: 'useParser',
            ...getColumnSearchOption(enumUseParser(), 'equals_bool'),
            render: (text: string, record: { [index: string]: any }) => {
                return record.useParser && record.useParser == true ? (
                    <div>
                        <Tooltip title={geti18nText('device.table.use.parser.true')}>
                            <ApiOutlined style={{ color: 'blue' }} />
                        </Tooltip>
                    </div>
                ) : (
                    <div>
                        <Tooltip title={geti18nText('device.table.use.parser.false')}>
                            <ApiOutlined style={{ color: 'gray' }} />
                        </Tooltip>
                    </div>
                );
            },
        },
        {
            title: geti18nText('device.table.column.name'),
            dataIndex: 'name',
            render: (text: string, record: { [index: string]: any }) => {
                return (
                    <div>
                        <div style={{ display: 'inline' }}>{record.name}</div>

                        <div style={{ display: 'inline', marginLeft: '5px' }}>
                            {record.hasComments == true ? <CommentComponent id={record.id}></CommentComponent> : null}
                        </div>
                    </div>
                );
            },
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('device.information.table.model'),
            dataIndex: ['model', 'id'],
            render: (text: string, record: { [index: string]: any }) => {
                if (record.model != null) {
                    return <div>{record.model.name}</div>;
                }
            },

            sorter: (a: any, b: any) => {},
            ...getColumnSearchOption(deviceModels()),
        },
        {
            title: geti18nText('device.table.column.office'),
            dataIndex: ['office', 'id'],
            sorter: (a: any, b: any) => {},
            render: (text: string, record: { [index: string]: any }) => {
                if (record.office != null) {
                    return <div>{record.office.name}</div>;
                }
            },
            ...getColumnSearchOption(offices()),
        },
        // {
        //     title: geti18nText('office.table.column.hnb_unit_code'),
        //     dataIndex: ['office', 'hnbUnitCode'],
        //     ...getColumnSearch('string'),
        //     sorter: (a: any, b: any) => {},
        // },
        {
            title: geti18nText('device.table.column.ip'),
            dataIndex: 'ip',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('device.edit.connection_type'),
            dataIndex: 'connectionType',
            sorter: (a: any, b: any) => {},
            ...getColumnSearchOption(deviceConnctionType()),
            render: (text: string, record: { [index: string]: any }) => {
                if (record.connectionType != null) {
                    return <div>{geti18nText('app.enum.DEVICE_CONNECTION_TYPE.' + record.connectionType)}</div>;
                }
            },
        },

        {
            title: geti18nText('device.edit.device.mode'),
            dataIndex: 'mode',
            sorter: (a: any, b: any) => {},

            ...getColumnSearchOption(deviceMode()),
        },
        // {
        //     title: geti18nText('device.edit.pbz.id'),
        //     dataIndex: 'pbzId',
        //     sorter: (a: any, b: any) => {},
        //     ...getColumnSearch('string'),
        // },

        {
            title: geti18nText('device.table.column.serial.number'),
            dataIndex: 'serialNumber',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('device.table.column.manufacturer'),
            dataIndex: ['manufacturer', 'id'],
            render: (text: string, record: { [index: string]: any }) => {
                if (record.manufacturer != null) {
                    return <div>{record.manufacturer.name}</div>;
                }
            },
            sorter: (a: any, b: any) => {},
            ...getColumnSearchOption(manufacturers()),
        },
        {
            title: geti18nText('device.table.column.inventory.number'),
            dataIndex: 'inventoryNumber',
            width: '5%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('device.table.column.group.names'),
            dataIndex: 'groupId',
            render: (text: string, record: { [index: string]: any }) => {
                if (text == undefined) {
                    return <></>;
                } else {
                    var array = text.split('||');

                    return (
                        <>
                            {array != null && array.length > 1 && (
                                <Popover
                                    placement="left"
                                    content={
                                        <div>
                                            {array.map((item: any) => {
                                                return (
                                                    <div>
                                                        <Text>{item}</Text>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    }
                                    overlayStyle={{
                                        whiteSpace: 'pre-line',
                                        maxHeight: '40vh',
                                        overflowY: 'auto',
                                        border: '1px solid #E0E0E0',
                                    }}
                                >
                                    <div>{array[0] + ' ... '}</div>
                                </Popover>
                            )}
                            {array != null && array.length == 1 && <div>{array[0]}</div>}
                        </>
                    );
                }
            },
            ...getColumnSearchOption(groups(groupIds)),
        },
        {
            title: geti18nText('device.table.column.ecb'),
            dataIndex: 'ecb',
            width: '1%',
            sorter: (a: any, b: any) => {},
            render: (text: any, record: { ecb: any }) => {
                if (record.ecb) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool'),
        },
        {
            title: geti18nText('device.table.column.last.activity'),
            dataIndex: 'lastActivity',
            width: '10%',
            sorter: (a: any, b: any) => {},
            render: (text: string, record: { [index: string]: any }) => {
                if (record.lastActivity) {
                    return (
                        <div>
                            {new Date(record.lastActivity).toLocaleString(NyUtils.getSelectedLocale(), getTimezone())}
                        </div>
                    );
                }
            },
            ...getColumnDateOption(true, undefined, undefined, true),
        },
        {
            title: geti18nText('device.model.table.licenseType'),
            dataIndex: ['model', 'licenseType'],
            sorter: (a: any, b: any) => {},
            render: (text: any, record: any) => {
                if (record.model && record.model.licenseType) {
                    if (record.useParser && record.useParser == true) {
                        return <Tag color="green">{record.model.licenseType}</Tag>;
                    } else {
                        return <Tag color="orange">{record.model.licenseType}</Tag>;
                    }
                }
            },
            ...getColumnSearchOption(licenseTypes()),
        },
        {
            title: geti18nText('manufacturer.alarm.table.active'),
            dataIndex: 'active',
            width: '1%',
            render: (text: any, record: { active: any }) => {
                if (record.active) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool'),
        },
    ];
    if (props && props.isAdmin && props.isAdmin === true && NySession.hasAnyRole(['RULE_PREVIEW_DEVICE_WRITTEN_OFF'])) {
        columns.push({
            title: geti18nText('device.table.column.written.off'),
            dataIndex: 'writtenOff',
            width: '1%',
            render: (text: any, record: { writtenOff: any }) => {
                if (record.writtenOff) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(WRITTEN_OFF(), 'equals_bool'),
        });
    }

    if (isEnterpreise() == true) {
        columns = columns.filter((x: any) => !x.dataIndex.includes('licenseType'));
    }

    columns.push(
        {
            //title: geti18nText('device.table.column.written.off'),
            dataIndex: 'correct',
            width: '1%',
            render: (text: any, record: { correct: any }) => {
                if (record.correct) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(CORRECT(), 'equals_bool'),
        },
        {
            //title: geti18nText('device.table.column.written.off'),
            dataIndex: 'repair',
            width: '1%',
            render: (text: any, record: { repair: any }) => {
                if (record.repair) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(REPAIR(), 'equals_bool'),
        }
    );

    return columns;
}

export default DeviceColumns;
