import { InfoCircleOutlined } from '@ant-design/icons';
import { geti18nText } from '@nybble/nyreact';
import { Popover, Progress, Table } from 'antd';
import { error } from 'console';

const LicenseTypePreviewTable = ({ dataSource, showInfo = false }: any) => {
    const columns = [
        {
            dataIndex: 'code',
            title: geti18nText('monitor.license.preview.licenseNumbers.table.code'),
            width: '30%',
        },
        {
            dataIndex: 'number',
            title: geti18nText('monitor.license.preview.licenseNumbers.table.number'),
            render: (text: any, record: any, index: any) => {
                if (record.enterprise == true) {
                    return null;
                }
                const number = record.number != undefined ? record.number : 0;
                const devices = record.devices;
                const p = (devices / number) * 100;
                let status: any = 'success';
                let showErrorMsg = false;
                if (p > 80) {
                    status = 'exception';
                }
                if (p > 100) {
                    showErrorMsg = showInfo;
                }

                return (
                    <Progress
                        percent={p}
                        status={status}
                        showInfo={true}
                        format={(percent, success) => {
                            return (
                                <div>
                                    <>{devices + '/' + number}</>
                                    {showErrorMsg && (
                                        <Popover
                                            content={
                                                <>{geti18nText('monitor.license.preview.license.table.err.msg')}</>
                                            }
                                        >
                                            {' '}
                                            <InfoCircleOutlined style={{ marginLeft: '5px', color: 'red' }} />
                                        </Popover>
                                    )}
                                </div>
                            );
                        }}
                    />
                );
            },
        },
    ];

    return (
        <>
            <Table rowKey="id" columns={columns} dataSource={dataSource} pagination={false} />
        </>
    );
};

export default LicenseTypePreviewTable;
