import { geti18nText } from '@nybble/nyreact';
import { Tabs } from 'antd';
import HnbReportsIndex from '.';

const HnbReportsMainIndex = () => {
    const { TabPane } = Tabs;

    return (
        <Tabs defaultActiveKey="1" type="card">
            <TabPane tab={geti18nText('hnbReport.tab.dailyReport')} key="1">
                <HnbReportsIndex period="D"> </HnbReportsIndex>
            </TabPane>
            <TabPane tab={geti18nText('hnbReport.tab.monthlyReport')} key="2">
                <HnbReportsIndex period="M"></HnbReportsIndex>
            </TabPane>
        </Tabs>
    );
};

export default HnbReportsMainIndex;
