import {
    geti18nText,
    NyDataEdit,
    NySession,
    NyMaskContent,
    NyRequestResolver,
    RESPONSE,
    NyUtils,
    NySearchField,
} from '@nybble/nyreact';
import {
    Col,
    Form,
    Input,
    Row,
    Tabs,
    Image,
    Descriptions,
    Tooltip,
    Button,
    Modal,
    InputNumber,
    notification,
} from 'antd';
import L from 'leaflet';
import React, { useEffect, useRef, useState } from 'react';
import NyImageUpload from '../../../components/file-upload';
import { CONSTANTS_REQ } from '../../../utils/Constants';

const HnbReportSettings = (props: any) => {
    const [formSettings] = Form.useForm();
    const setDefaultFilterValue = () => {
        return [{ field: 'setting_group', condition: 'equals', value: 'APP_SETTINGS' }];
    };

    useEffect(() => {
        fetch();
    }, []);

    async function setValues(dataForm: any) {
        console.log(dataForm);
        if (dataForm != undefined) {
            dataForm.bankCode = dataForm.BANK_CODE;
            dataForm.bankOib = dataForm.BANK_OIB;
            dataForm.bankEmployer = dataForm.BANK_EMPLOYER;
            dataForm.currencyDenomination = {
                id: dataForm.CURRENCY_DENOMINATION.id,
                name: dataForm.CURRENCY_DENOMINATION.currencyCode,
            };
            delete dataForm.BANK_CODE;
            delete dataForm.BANK_OIB;
            delete dataForm.BANK_EMPLOYER;
            delete dataForm.CURRENCY_DENOMINATION;
            formSettings.setFieldsValue(dataForm);
        }
    }

    const fetch = () => {
        NyRequestResolver.requestGet(CONSTANTS_REQ.APPLICATION_SETTINGS.HNB_REPORT).then((result: any) => {
            if (result.status === RESPONSE.OK) {
                if (result.data) {
                    setValues(result.data);
                }
            }
        });
    };

    const saveAppSettings = () => {
        console.log('save');
        formSettings.validateFields().then((fields: any) => {
            NyRequestResolver.requestPost(CONSTANTS_REQ.APPLICATION_SETTINGS.HNB_REPORT, undefined, fields).then(
                (result: any) => {
                    if (result && result.status === RESPONSE.OK) {
                        notification.success({
                            message: geti18nText('app.default.save.ok'),
                            description: geti18nText('app.default.save.ok.desc'),
                            duration: 4,
                        });
                    } else {
                        notification.error({
                            message: geti18nText('app.default.save.nok'),
                            description: result.data?.message,
                            duration: 5,
                        });
                    }
                }
            );
        });
    };

    return (
        <Row>
            <Form form={formSettings} onFinish={() => saveAppSettings()}>
                <Row gutter={24} style={{ marginTop: '15px' }}>
                    <Col span={12}>
                        <Form.Item
                            label={geti18nText('settings.hnbReport.currencyDenomination')}
                            name="currencyDenomination"
                        >
                            <NySearchField
                                url={CONSTANTS_REQ.CURRENCY.SEARCH}
                                map={{ id: 'id', label: 'name' }}
                                searchBy="name"
                            />
                        </Form.Item>
                    </Col>
                    {/* <Col span={12}>
                        <Form.Item label={geti18nText('settings.hnbReport.bankCode')} name="bankCode">
                            <Input />
                        </Form.Item>
                    </Col> */}

                    <Col span={12}>
                        <Form.Item label={geti18nText('settings.hnbReport.bankOib')} name="bankOib">
                            <Input />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item label={geti18nText('settings.hnbReport.bankEmployer')} name="bankEmployer">
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col offset={21}>
                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                                {geti18nText('settings.work.btn.save')}
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Row>
    );
};

export default HnbReportSettings;
