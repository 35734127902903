import { geti18nText } from '@nybble/nyreact';
import { Button, Modal } from 'antd';
import { useEffect, useState } from 'react';
import './patternModal.scss';
import { formatCurrency } from '../../../utils/Utils';

const LiveCountingDetailDisplay = ({
    details,
    columns = ['denomination', 'pcs'],
    liveDataSum,
    liveDataCurrency,
}: any) => {
    const headers = [
        { label: 'denomination', key: 'value' },
        { label: 'series', key: 'series' },
        { label: 'fit', key: 'fit' },
        { label: 'unfit', key: 'unfit' },
        { label: 'atm', key: 'atm' },
        { label: 'tlr', key: 'tlr' },
        { label: 'pcs', key: 'quantity' },
    ];

    const [headerColumns, setHeaderColumns] = useState<any>(headers);
    const [detailItems, setDetailItems] = useState<any>(headers);

    useEffect(() => {
        let labels = columns != undefined && columns.length > 0 ? columns : ['denomination', 'pcs'];
        let items = [...headers];
        items = items.filter((x) => labels.includes(x.label));
        setHeaderColumns(items);
        const isSerialColumnExist = columns.includes('series');
        if (!isSerialColumnExist) {
            const groupedItems = details?.reduce((acc: any, item: any) => {
                const existing = acc.find((group: any) => group.value == item.value);
                if (existing) {
                    existing.quantity += item.quantity;
                    existing.tlr += item.tlr;
                    existing.atm += item.atm;
                    existing.fit += item.unfit;
                    existing.unfit += item.unfit;
                } else {
                    acc.push({
                        value: item.value,
                        quantity: item.quantity,
                        tlr: item.tlr,
                        atm: item.atm,
                        fit: item.fit,
                        unfit: item.unfit,
                    });
                }
                return acc;
            }, []);
            setDetailItems(groupedItems);
        } else {
            setDetailItems(details);
        }
    }, [columns, details]);

    return (
        <>
            <div
                style={{
                    marginLeft: '20%',
                    marginRight: '20%',
                    minHeight: '300px',
                    maxHeight: '400px',
                    overflowY: 'auto',
                }}
            >
                <table className="live-preview-detail-table" style={{ marginTop: '20px', width: '100%' }}>
                    <thead>
                        {headerColumns.map((value: any, index: any) => {
                            return (
                                <th
                                    key={index}
                                    style={{ textAlign: 'center', color: '#8181A5', backgroundColor: '#E3E8F4' }}
                                >
                                    {geti18nText('device.live.counting.view.counting.display.column.' + value.label)}
                                </th>
                            );
                        })}
                    </thead>
                    <tbody>
                        {detailItems.map((item: any, colIndex: any) => (
                            <tr key={colIndex}>
                                {headerColumns.map((field: any, rowIndex: any) => (
                                    <td key={rowIndex}>{item[field.key]}</td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div>
                <div
                    style={{
                        marginTop: '20px',
                        display: 'flex',
                        backgroundColor: '#4D7CFE',
                        height: '45px',
                        marginLeft: '30%',
                        marginRight: '10%',
                    }}
                >
                    <div
                        style={{
                            padding: '2%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                            fontSize: '26px',
                            color: 'white',
                        }}
                    >
                        {geti18nText('app.default.total')}
                    </div>
                    <div
                        style={{
                            paddingBottom: '2%',
                            paddingRight: '10%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            marginLeft: 'auto',
                            fontSize: '36px',
                            color: 'white',
                        }}
                    >
                        {formatCurrency(liveDataSum, liveDataCurrency)}
                    </div>
                </div>
            </div>
        </>
    );
};
export default LiveCountingDetailDisplay;
