import { geti18nText } from '@nybble/nyreact';
import { Button, Modal } from 'antd';
import { useState } from 'react';
import './patternModal.scss';
import { formatCurrency } from '../../../utils/Utils';

const LiveCountingDisplay = ({ liveDataSum, liveDataCurrency, liveDataCount }: any) => {
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                marginLeft: '25%',
                marginRight: '25%',
                marginTop: '1%',
            }}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    backgroundColor: '#E3E8F4',
                    marginTop: '4%',
                    borderTop: 'thin solid black',
                    borderLeft: 'thin solid black',
                    borderRight: 'thin solid black',
                    borderTopRightRadius: '5px',
                    borderTopLeftRadius: '5px',
                    height: '130px',
                    justifyContent: 'space-between',
                    alignItems: 'flex-end',
                    paddingBottom: '2%',
                    paddingLeft: '10%',
                    paddingRight: '10%',
                    boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.5)',
                }}
            >
                <div>
                    <div
                        style={{
                            fontSize: '1.8em',
                            textTransform: 'uppercase',
                            color: '#1C1D21',
                            fontWeight: 'bold',
                        }}
                    >
                        {geti18nText('device.live.counting.view.total')}{' '}
                    </div>
                </div>
                <div>
                    <div
                        style={{
                            fontSize: '46px',
                            color: '#1C1D21',
                            fontStyle: 'italic',
                        }}
                    >
                        {formatCurrency(liveDataSum, liveDataCurrency)}
                    </div>
                </div>
            </div>

            <div
                style={{
                    display: 'flex',
                    backgroundColor: '#4D7CFE',
                    minHeight: '200px',
                    border: 'solid',
                    borderWidth: 'thin',
                    borderColor: 'black',
                    borderRadius: '5px',
                    boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.5)',
                }}
            >
                <div
                    style={{
                        paddingBottom: '2%',
                        paddingRight: '10%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        marginLeft: 'auto',
                    }}
                >
                    <div style={{ fontSize: '76px', color: 'white' }}>
                        {liveDataCount}
                        <span style={{ fontSize: '36px', paddingLeft: '25px' }}>
                            {geti18nText('device.live.counting.view.pcs')}{' '}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default LiveCountingDisplay;
