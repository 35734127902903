import { geti18nText } from '@nybble/nyreact';
import { Button, Modal } from 'antd';
import { useEffect, useState } from 'react';

const DetailDisplayModal = ({
    visibleDisplayDetailModal,
    setDetailDisplaySettings,
    setVisibleDisplayDetailModal,
    setColumns,
}: any) => {
    const [selectedItems, setSelectedItems] = useState<{ type0: string; type1: string }>({
        type0: 'MIX',
        type1: 'MIX1',
    });

    const headers = [
        { label: 'revision', key: 'col1' },
        { label: 'fitnes', key: 'col2' },
    ];

    const details = [
        {
            col1: { name: 'MIX', key: 'MIX', type: 0, columns: ['denomination'] },
            col2: { name: 'MIX', key: 'MIX1', columns: ['pcs'], type: 1 },
        },
        {
            col1: { name: 'NEW/OLD', key: 'NEW/OLD', type: 0, columns: ['denomination', 'series'] },
            col2: { name: 'FIT/UNFIT', key: 'FIT/UNFIT', columns: ['fit', 'unfit', 'pcs'], type: 1 },
        },
        {
            col1: { name: 'DETAIL', key: 'DETAIL', type: 0, columns: ['denomination', 'series'] },
            col2: { name: 'ATM/TLR/UNFIT', key: 'ATM/TLR/UNFIT', columns: ['atm', 'tlr', 'unfit'], type: 1 },
        },
    ];
    const handleButtonClick = (item: any) => {
        const { type, key } = item;

        if (type === 0) {
            setSelectedItems((prevState) => ({
                ...prevState,
                type0: key,
            }));
        } else if (type === 1) {
            setSelectedItems((prevState) => ({
                ...prevState,
                type1: key,
            }));
        }
    };

    const handleOk = () => {
        const selectedDetailCol1 = details.find((detail) => detail.col1.key === selectedItems.type0);
        const selectedDetailCol2 = details.find((detail) => detail.col2.key === selectedItems.type1);
        let columns = selectedDetailCol1?.col1.columns.concat(selectedDetailCol2?.col2.columns!);
        setColumns(columns);
        setVisibleDisplayDetailModal(false);
    };

    const renderRows = () => {
        return details.map((item: any, colIndex: any) => (
            <tr key={colIndex}>
                {headers.map((field: any, rowIndex: any) => (
                    <td key={rowIndex}>
                        {
                            <Button
                                style={{
                                    width: '250px',
                                    fontSize: '26px',
                                    color:
                                        (item[field.key].type === 0 && selectedItems.type0 === item[field.key].key) ||
                                        (item[field.key].type === 1 && selectedItems.type1 === item[field.key].key)
                                            ? 'white'
                                            : '#8181A5',
                                    backgroundColor:
                                        (item[field.key].type === 0 && selectedItems.type0 === item[field.key].key) ||
                                        (item[field.key].type === 1 && selectedItems.type1 === item[field.key].key)
                                            ? '#4D7CFE'
                                            : '#E3E8F4',
                                    borderWidth: 'thin',
                                    borderRadius: '5px',
                                    marginLeft: '20px',
                                    minWidth: '130px',
                                    height: '65px',
                                    boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.5)',
                                    cursor: 'pointer',
                                    marginBottom: '10px',
                                }}
                                onClick={() => {
                                    handleButtonClick(item[field.key]);
                                }}
                            >
                                {item[field.key].name}
                            </Button>
                        }
                    </td>
                ))}
            </tr>
        ));
    };

    return (
        <Modal
            title={<h2>{geti18nText('device.live.counting.view.counting.display')}</h2>}
            visible={visibleDisplayDetailModal}
            onCancel={() => setVisibleDisplayDetailModal(false)}
            width={1000}
            footer={[
                <Button className="live-preview-modal-button" onClick={() => setVisibleDisplayDetailModal(false)}>
                    {geti18nText('app.default.button.cancel')}
                </Button>,
                <Button className="live-preview-modal-button" onClick={handleOk}>
                    {geti18nText('app.default.button.ok')}
                </Button>,
            ]}
        >
            <table className="live-preview-display-detail-table" style={{ marginLeft: '20%', marginRight: '25%' }}>
                <thead>
                    {headers.map((value, index) => {
                        return (
                            <th key={index} style={{ textAlign: 'center' }}>
                                {geti18nText('device.live.counting.view.counting.display.' + value.label)}
                            </th>
                        );
                    })}
                </thead>
                <tbody>{renderRows()}</tbody>
            </table>
        </Modal>
    );
};

export default DetailDisplayModal;
