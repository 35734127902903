import {
    geti18nText,
    NyDataEdit,
    NyRequestResolver,
    NySession,
    NyUtils,
    RESPONSE,
    NyMaskContent,
    NySearchField,
} from '@nybble/nyreact';
import { Checkbox, Col, Descriptions, Divider, Form, Input, Row, Select, Space, Tabs } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { normalize } from 'path';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { CONSTANTS_REQ, URL_PREFIX } from '../../utils/Constants';
import { GetEnum } from '../../utils/Enums';
const { TabPane } = Tabs;
const CheckboxGroup = Checkbox.Group;

const RoleEdit = (props: any) => {
    const [editHeader, setEditHeader] = useState(geti18nText('roles.table.header'));
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const history = useHistory();
    const { id } = useParams<any>();
    const [isCreate, setIsCreate] = useState(false);
    const [userItem, setUserItem] = useState<any>(null);
    const [checkedList, setCheckedList] = React.useState<any>(null);
    const [checkedDeviceList, setDeviceCheckedList] = React.useState<any>(null);
    const [checkedOthereList, setOtherCheckedList] = React.useState<any>(null);
    const [checkedReportList, setReportCheckedList] = React.useState<any>(null);
    const [indeterminate, setIndeterminate] = React.useState(true);
    const [indeterminateDevice, setDeviceIndeterminate] = React.useState(true);
    const [indeterminateOther, setOtherIndeterminate] = React.useState(true);
    const [indeterminateReport, setReportIndeterminate] = React.useState(true);
    const [checkAll, setCheckAll] = React.useState(false);
    const [checkAllDevice, setCheckAllDevice] = React.useState(false);
    const [checkAllOther, setCheckAllOther] = React.useState(false);
    const [checkAllReport, setCheckAllReport] = React.useState(false);
    const [userRuleTypes, setUserRuleTypes] = useState<any>(null);
    const [checkCompanyPolicy, setCheckCompanyPolicy] = useState<any>(false);
    const [checkBranchPolicy, setChecBranchPolicy] = useState<any>(false);

    useEffect(() => {
        //   const rules = ruleTypes('USER');
        // setUserRuleTypes(rules);
        tabOnChange('1');
    }, []);

    const onModalClose = () => {
        form.resetFields();
        setEditHeader(geti18nText('roles.table.header'));
        setChecBranchPolicy(false);
        setCheckCompanyPolicy(false);
    };

    function setValues(dataForm: any) {
        setEditHeader(dataForm.authority);
        console.log(dataForm);
        delete dataForm.password;
        const userRules2d = ruleTypes('USER');
        const userRules = userRules2d.reduce((prev, next) => {
            return prev.concat(next);
        });
        const deviceRules = ruleTypes('DEVICE').reduce((prev, next) => {
            return prev.concat(next);
        });

        const otherRules = ruleTypes('OTHER').reduce((prev, next) => {
            return prev.concat(next);
        });

        const reportRules = ruleTypes('REPORT').reduce((prev, next) => {
            return prev.concat(next);
        });

        setUserRuleTypes(userRules2d);
        setCheckedList([]);
        setDeviceCheckedList([]);
        setOtherCheckedList([]);
        setReportCheckedList([]);
        setIndeterminate(true);
        setDeviceIndeterminate(true);
        setOtherIndeterminate(true);
        setReportIndeterminate(true);

        if (dataForm.isBranch) setChecBranchPolicy(dataForm.isBranch);
        if (dataForm.isCompany) setCheckCompanyPolicy(dataForm.isCompany);
        if (Array.isArray(dataForm.rules)) {
            const userItems = dataForm.rules.map((rule: { [index: string]: any }) => {
                return userRules.map((x) => x.value).includes(rule.rule) ? rule.rule : null;
            });
            setCheckAll(!!userItems.length && userItems.length < userItems.length);
            setCheckedList(userItems);
            const deviceItems = dataForm.rules.map((rule: { [index: string]: any }) => {
                return deviceRules.map((x) => x.value).includes(rule.rule) ? rule.rule : null;
            });
            setCheckAllDevice(!!deviceItems.length && deviceItems.length < deviceRules.length);
            setDeviceCheckedList(deviceItems);

            const otherItems = dataForm.rules.map((rule: { [index: string]: any }) => {
                return otherRules.map((x) => x.value).includes(rule.rule) ? rule.rule : null;
            });
            setCheckAllOther(!!otherItems.length && otherItems.length < otherRules.length);
            setOtherCheckedList(otherItems);

            const reportItems = dataForm.rules.map((rule: { [index: string]: any }) => {
                return reportRules.map((x) => x.value).includes(rule.rule) ? rule.rule : null;
            });
            setCheckAllReport(!!reportItems.length && reportItems.length < reportItems.length);
            setReportCheckedList(reportItems);
        }
        delete dataForm.rules;
        form.setFieldsValue(dataForm);

        setUserItem(dataForm);
    }

    const validateAuthority = (rule: any, value: any, callback: any) => {
        if (!/^[a-zA-Z0-9-_]*$/.test(value)) {
            callback(geti18nText('roles.edit.name.validate'));
            return;
        }
        callback();
    };

    const onChange = (list: any) => {
        const rules = userRuleTypes.reduce((prev: any, next: any) => {
            return prev.concat(next);
        });
        setCheckedList(list);
        setIndeterminate(!!list.length && list.length < rules.length);
        setCheckAll(list.length === userRuleTypes.length);
    };

    const onCheckAllChange = (e: any) => {
        const rules = userRuleTypes.reduce((prev: any, next: any) => {
            return prev.concat(next);
        });
        setCheckedList(e.target.checked ? rules.map((i: any) => i.value) : []);
        setIndeterminate(false);
        setCheckAll(e.target.checked);
    };

    const onChangeDevice = (list: any) => {
        const rules = userRuleTypes.reduce((prev: any, next: any) => {
            return prev.concat(next);
        });
        setDeviceCheckedList(list);
        setDeviceIndeterminate(!!list.length && list.length < rules.length);
        setCheckAllDevice(list.length === rules.length);
    };

    const onCheckAllChangeDevice = (e: any) => {
        const rules = userRuleTypes.reduce((prev: any, next: any) => {
            return prev.concat(next);
        });
        setDeviceCheckedList(e.target.checked ? rules.map((i: any) => i.value) : []);
        setDeviceIndeterminate(false);
        setCheckAllDevice(e.target.checked);
    };

    const onChangeOther = (list: any) => {
        setOtherCheckedList(list);
        const otherRules = userRuleTypes.reduce((prev: any, next: any) => {
            return prev.concat(next);
        });
        setOtherIndeterminate(!!list.length && list.length < otherRules.length);
        setCheckAllOther(list.length === otherRules.length);
    };

    const onChangeReport = (list: any) => {
        setReportCheckedList(list);
        const reportRules = userRuleTypes.reduce((prev: any, next: any) => {
            return prev.concat(next);
        });
        setReportIndeterminate(!!list.length && list.length < reportRules.length);
        setCheckAllReport(list.length === reportRules.length);
    };

    const onCheckAllChangeOther = (e: any) => {
        const otherRules = userRuleTypes.reduce((prev: any, next: any) => {
            return prev.concat(next);
        });
        setOtherCheckedList(e.target.checked ? otherRules.map((i: any) => i.value) : []);
        setOtherIndeterminate(false);
        setCheckAllOther(e.target.checked);
    };

    const onCheckAllChangeReport = (e: any) => {
        const reportRules = userRuleTypes.reduce((prev: any, next: any) => {
            return prev.concat(next);
        });
        setReportCheckedList(e.target.checked ? reportRules.map((i: any) => i.value) : []);
        setReportIndeterminate(false);
        setCheckAllReport(e.target.checked);
    };

    const ruleTypes = (group: 'USER' | 'DEVICE' | 'OTHER' | 'REPORT') => {
        const aTypes: any = GetEnum({ enumName: 'USER_RULE_TYPE' });
        let types = [];
        if (aTypes != undefined) {
            const items = aTypes[group];
            var i = 0;
            for (var keyRow in items) {
                let subItems = [];
                for (var k in items[keyRow]) {
                    let ret = { value: undefined, label: '' };
                    ret.value = items[keyRow][k];
                    ret.label = geti18nText('roles.enum.rule.' + items[keyRow][k]);
                    subItems.push(ret);
                }
                types[i] = subItems;
                i++;
            }
        }

        return types;
    };

    const tabOnChange = (activeKey: any) => {
        if (activeKey === '1') {
            setUserRuleTypes(ruleTypes('USER'));
        }
        if (activeKey === '2') {
            setUserRuleTypes(ruleTypes('DEVICE'));
        }
        if (activeKey === '3') {
            setUserRuleTypes(ruleTypes('OTHER'));
        }
        if (activeKey === '4') {
            setUserRuleTypes(ruleTypes('REPORT'));
        }
    };

    function normalizeValues(values: any) {
        console.log(values);
        let normalized = { ...values };
        if (checkedList) {
            normalized.ruleItems = checkedList
                .concat(checkedDeviceList)
                .concat(checkedOthereList)
                .concat(checkedReportList);
        }
        normalized.isBranch = checkBranchPolicy;
        normalized.isCompany = checkCompanyPolicy;
        return normalized;
    }

    const onCheckPolicy = (e: any) => {
        if (e.target.id == 'office') {
            setChecBranchPolicy(e.target.checked);
            if (checkCompanyPolicy) {
                setCheckCompanyPolicy(false);
            }
        } else {
            setCheckCompanyPolicy(e.target.checked);
            if (checkBranchPolicy) {
                setChecBranchPolicy(false);
            }
        }
    };

    const ChecboxRow = (list: any) => {
        return (
            <Row gutter={[12, 12]}>
                {list != null &&
                    list.map((label: any, i: number) => (
                        <Col>
                            <Checkbox style={{ marginRight: 20, minWidth: 250 }} key={label.value} value={label.value}>
                                {label.label}
                            </Checkbox>
                        </Col>
                    ))}

                <Divider></Divider>
            </Row>
        );
    };

    return (
        <NyDataEdit
            layout="horizontal"
            formProps={{ labelCol: { span: 7 }, wrapperCol: { span: 17 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            url={CONSTANTS_REQ.ROLE.EDIT}
            setValues={setValues}
            width={1200}
            form={form}
            goBack={() => history.goBack()}
            paramsId={id}
            setIsCreate={setIsCreate}
            normalize={normalizeValues}
            hideButtons={!NySession.hasAnyRole(['RULE_UPDATE_USER_ROLE'])}
            {...props}
        >
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item name="id" style={{ display: 'none' }}>
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label={geti18nText('roles.edit.name')}
                        name="authority"
                        normalize={(value) => (value || '').toUpperCase()}
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                            },
                            {
                                min: 3,
                                message: geti18nText('roles.edit.name.length.validate'),
                            },
                            {
                                validator: validateAuthority,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Divider orientation="left">{geti18nText('roles.review.policy.title')}</Divider>
            <Row gutter={24}>
                <Col span={12} style={{ marginLeft: '30px' }}>
                    <Checkbox id="company" checked={checkCompanyPolicy} onChange={onCheckPolicy}>
                        {geti18nText('roles.policy.company')}
                    </Checkbox>
                    <Checkbox id="office" checked={checkBranchPolicy} onChange={onCheckPolicy}>
                        {geti18nText('roles.policy.branch')}
                    </Checkbox>
                </Col>
            </Row>
            <Divider style={{ width: '100%' }} orientation="left">
                {geti18nText('user.edit.divider.safety')}
            </Divider>
            <Row gutter={24}>
                {userRuleTypes != null && (
                    <Tabs type="card" style={{ marginLeft: '50px' }} onChange={tabOnChange}>
                        <TabPane tab={geti18nText('roles.edit.tab.users')} key="1">
                            <Row>
                                <Form.Item name="rules">
                                    <Checkbox
                                        indeterminate={indeterminate}
                                        onChange={onCheckAllChange}
                                        checked={checkAll}
                                    >
                                        {geti18nText('roles.enum.rule.check_all')}
                                    </Checkbox>
                                    <Divider />
                                    <CheckboxGroup value={checkedList} onChange={onChange} style={{ width: '1100px' }}>
                                        {userRuleTypes.map((label: any, i: number) => ChecboxRow(label))}
                                    </CheckboxGroup>
                                </Form.Item>
                            </Row>
                        </TabPane>
                        <TabPane tab={geti18nText('roles.edit.tab.devices')} key="2">
                            <Row>
                                <Form.Item name="rules">
                                    <Checkbox
                                        indeterminate={indeterminateDevice}
                                        onChange={onCheckAllChangeDevice}
                                        checked={checkAllDevice}
                                    >
                                        {geti18nText('roles.enum.rule.check_all')}
                                    </Checkbox>
                                    <Divider />
                                    <CheckboxGroup
                                        style={{ width: '1300px' }}
                                        value={checkedDeviceList}
                                        onChange={onChangeDevice}
                                    >
                                        {userRuleTypes.map((label: any, i: number) => ChecboxRow(label))}
                                    </CheckboxGroup>
                                </Form.Item>
                            </Row>
                        </TabPane>
                        <TabPane tab={geti18nText('roles.edit.tab.general')} key="3">
                            <Row>
                                <Form.Item name="general">
                                    <Checkbox
                                        indeterminate={indeterminateOther}
                                        onChange={onCheckAllChangeOther}
                                        checked={checkAllOther}
                                    >
                                        {geti18nText('roles.enum.rule.check_all')}
                                    </Checkbox>
                                    <Divider />
                                    <CheckboxGroup
                                        style={{ width: '1100px' }}
                                        value={checkedOthereList}
                                        onChange={onChangeOther}
                                    >
                                        {userRuleTypes.map((label: any, i: number) => ChecboxRow(label))}
                                    </CheckboxGroup>
                                </Form.Item>
                            </Row>
                        </TabPane>
                        <TabPane tab={geti18nText('roles.edit.tab.report')} key="4">
                            <Row>
                                <Form.Item name="report">
                                    <Checkbox
                                        indeterminate={indeterminateReport}
                                        onChange={onCheckAllChangeReport}
                                        checked={checkAllReport}
                                    >
                                        {geti18nText('roles.enum.rule.check_all')}
                                    </Checkbox>
                                    <Divider />
                                    <CheckboxGroup
                                        style={{ width: '1100px' }}
                                        value={checkedReportList}
                                        onChange={onChangeReport}
                                    >
                                        {userRuleTypes.map((label: any, i: number) => ChecboxRow(label))}
                                    </CheckboxGroup>
                                </Form.Item>
                            </Row>
                        </TabPane>
                    </Tabs>
                )}
            </Row>
        </NyDataEdit>
    );
};

export default RoleEdit;
